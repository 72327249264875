.preloader {
    background-color: var(--theme-text-color);
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 1;
    transition: 1s opacity;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
}

.preloader.loaded {
    opacity: 0;
    visibility: hidden;
}

.preloader .spinner_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 33333;
}

