.navbar-brand, .navbar-brand:hover, .navbar-brand:focus {
    color: var(--theme-text-color);
    font-size: xx-large;
}

.navbar-brand > img {
    width: 48px;
    height: 48px;
}

@media (max-width: 768px) {
    .navbar-brand, .navbar-brand:hover , .navbar-brand:focus{
        font-size: x-large;
    }

    .navbar-brand > img {
        width: 32px;
        height: 32px;
    }
}

.navbar > .container {
    justify-content: center;
}
